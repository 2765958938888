<template>
  <b-card>
      <h3>Consignar del ChatBot</h3>
      <p>Aquí puedes gestionar las Consignar del ChatBot.</p>
  </b-card>

</template>

<script>
export default {
  name: 'Consignar',
}
</script>